import { createRouter, createWebHistory } from 'vue-router';
import ReportsPage from "@/components/ReportsPage.vue";
import GeneralForm from "@/components/GeneralForm.vue";
import AIRecommendationsPage from "@/components/AIRecomendationsPage.vue";
import NursingRecordsPage from "@/page/NursingRecordsPage.vue";
import HomePage from "@/page/HomePage.vue";
import FoodPage from "@/page/FoodPage.vue";
import DiaperPage from "@/page/DiaperPage.vue";
import MedicinePage from "@/page/MedicinePage.vue";
import MeasurementsPage from "@/page/MeasurementsPage.vue";
import ProfilePage from "@/page/ProfilePage.vue";
import WeightGraphPage from "@/page/WeightGraphPage.vue";
import LoginPage from "@/page/LoginPage.vue";
import SettingsPage from "@/page/SettingsPage.vue";

import { useUserStore } from "@/stores/user.store";
import SignupPage from "@/page/SignupPage.vue";
import NewAI from "@/page/NewAI.vue";
import ChartPage from "@/page/ChartPage.vue";


const routes = [
    { path: '/', component: HomePage },
    { path: '/login', component: LoginPage },
    { path: '/signup', component: SignupPage },
    { path: '/food', component: FoodPage , meta: { requiresAuth: true }},
    { path: '/diaper', component: DiaperPage, meta: { requiresAuth: true } },
    { path: '/medication', component: MedicinePage , meta: { requiresAuth: true } },
    { path: '/measurements', component: MeasurementsPage, meta: { requiresAuth: true } },
    { path: '/profile', component: ProfilePage , meta: { requiresAuth: true } },
    { path: '/account', component: GeneralForm , meta: { requiresAuth: true } },
    { path: '/weight-graph', component: WeightGraphPage , meta: { requiresAuth: true } },
    { path: '/reports', component: ReportsPage , meta: { requiresAuth: true } },
    { path: '/recommendations', component: AIRecommendationsPage, meta: { requiresAuth: true } },
    { path: '/nursing', component: NursingRecordsPage , meta: { requiresAuth: true } },
    { path: '/settings', component: SettingsPage , meta: { requiresAuth: true } },
    { path: '/ai', component: NewAI , meta: { requiresAuth: true } },
    { path: '/chart', component: ChartPage , meta: { requiresAuth: true } },
];

const router = createRouter({
    history: createWebHistory('/app/'),
    routes
});

router.beforeEach(async (to, from, next) => {
    const userStore = useUserStore();

    if (to.path === '/logout') {
        console.log('router: logout routing to login');
        await userStore.logout();
        next('/login');
        return;
    }

    if ((to.path === '/login' || to.path === '/signup')) {
        if (userStore.isLoggedIn) {
            console.log('router: login/signup on logged in user routing to home');
            next('/');
            return;
        } else {
            await userStore.load()
            if (userStore.isLoggedIn) {
                console.log('router: login/signup on logged in user routing to home');
                next('/');
                return;
            } else {
                console.log('router: login/signup on user missing auth tokens routing to login');
                next();
                return;
            }
        }
    }

    if(!userStore.isLoggedIn) {
        await userStore.load()
        if (!userStore.isLoggedIn) {
            console.log('router: requiresAuth path user not logged in routing to login');
            next('/login');
            return;
        }
    }

    console.log(`router: valid rout routing to ${to.path} is logged in ${userStore.isLoggedIn}`, );
    next();
});

export default router;

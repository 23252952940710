<template>
  <div class="fog-container">
    <!--
      Fog Circle
      - Applies idle "breathing" animation when neither listening nor speaking.
      - When isListening or isSpeaking is true, JavaScript-driven animation
        via requestAnimationFrame will scale/pulse this circle based on
        real-time audio amplitude (microphone input or TTS approximation).
    -->
    <div ref="fogCircle" class="fog-circle"></div>

    <!--
      Push-to-Talk Button
      - Press (mousedown) to start listening, release (mouseup) to stop.
      - On "mouseup", we finalize recognition, send text to backend, and
        then speak the returned response.
    -->
    <button
        class="ptt-button"
        @mousedown="startListening"
        @mouseup="stopListening"
        @touchstart.prevent="startListening"
        @touchend.prevent="stopListening"
        @touchcancel.prevent="stopListening"
    >
      {{ isListening ? 'Release to Stop' : 'Hold to Talk' }}
    </button>
  </div>
</template>

<script setup>
/*
  This Vue 3 component demonstrates:
  1. Browser-based Speech Recognition (for capturing user voice).
  2. Browser-based Speech Synthesis (for TTS playback).
  3. A Web Audio API AnalyserNode for volume-based animation of a "fog circle."
     - When listening, the fog circle reflects the user's mic volume.
     - When speaking, it attempts to reflect TTS audio volume (via a simplified or approximate approach,
       as direct TTS-to-AnalyserNode routing is often not fully supported in browsers).
  4. All server requests use the native fetch API (no Axios).
*/

import { ref, onMounted, onBeforeUnmount } from 'vue';

// ----------------------------------------------------
// Reactive State
// ----------------------------------------------------
const isListening = ref(false);
const isSpeaking = ref(false);
const transcript = ref('');
const responseText = ref('');
// We'll store the current amplitude (volume level) for the fog circle.
const volumeLevel = ref(0);

// References
const fogCircle = ref(null);

// Web Audio API
let audioContext;
let analyser;
let microphoneSourceNode;
let dataArray;
let animationFrameId;

// For Speech Recognition (Web Speech API)
let recognition;
let isRecognitionSupported = false;

// On mount, check for SpeechRecognition support and set up if available.
onMounted(() => {
  if ('SpeechRecognition' in window || 'webkitSpeechRecognition' in window) {
    const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
    recognition = new SpeechRecognition();
    recognition.interimResults = false;
    recognition.continuous = false;
    recognition.lang = 'en-US';
    isRecognitionSupported = true;

    // Speech recognition event handlers
    recognition.onresult = handleSpeechResult;
    recognition.onend = handleSpeechEnd;
    recognition.onerror = handleSpeechError;
  }

  // Create an AudioContext for analysis (and possibly for TTS hooking).
  audioContext = new AudioContext();

  // Create an AnalyserNode.
  analyser = audioContext.createAnalyser();
  analyser.fftSize = 512;
  // We'll read the time-domain data, but you could also use getByteFrequencyData().
  dataArray = new Uint8Array(analyser.fftSize);

  // Start the animation loop for the fog circle volume.
  animateFogCircle();
});

onBeforeUnmount(() => {
  // Cleanup: stop any running animation frames or audio streams
  cancelAnimationFrame(animationFrameId);
  stopMicStream();
});

// ----------------------------------------------------
// Speech Recognition Methods
// ----------------------------------------------------
function startListening() {
  if (!isRecognitionSupported) {
    console.warn('Speech Recognition not supported in this browser.');
    return;
  }

  // Request microphone access and start recognition + audio analysis
  navigator.mediaDevices.getUserMedia({ audio: true })
      .then(stream => {
        // Create a Microphone Source Node
        microphoneSourceNode = audioContext.createMediaStreamSource(stream);
        // Connect microphone -> analyser
        microphoneSourceNode.connect(analyser);

        // Resume audio context if it is suspended (common on user gesture)
        if (audioContext.state === 'suspended') {
          audioContext.resume();
        }

        // Start Speech Recognition
        isListening.value = true;
        recognition.start();
      })
      .catch(err => {
        console.error('Error accessing microphone:', err);
        isListening.value = false;
      });
}

// Called when user releases the push-to-talk button
function stopListening() {
  if (isListening.value) {
    isListening.value = false;
    if (recognition) {
      recognition.stop();
    }
    // We'll disconnect the mic to stop volume updates from the microphone,
    // but only after we get the final recognized text in onend.
  }
}

function handleSpeechResult(event) {
  // The final transcript is in event.results
  const results = event.results;
  const lastResultIndex = results.length - 1;
  transcript.value = results[lastResultIndex][0].transcript.trim();
}

function handleSpeechEnd() {
  // Called when speech recognition ends naturally or forcibly (stopListening)
  isListening.value = false;
  stopMicStream();

  // If we have some transcript, let's send it to the server
  if (transcript.value) {
    sendToServer(transcript.value);
  }
}

function handleSpeechError(event) {
  console.error('Speech recognition error:', event.error);
  isListening.value = false;
  stopMicStream();
}

// Stop and disconnect the microphone stream
function stopMicStream() {
  if (microphoneSourceNode) {
    microphoneSourceNode.disconnect();
    microphoneSourceNode = null;
  }
}

// ----------------------------------------------------
// Server Communication (Fetch)
// ----------------------------------------------------
async function sendToServer(text) {
  try {
    const response = await fetch('/api/ai', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ userText: text })
    });

    if (!response.ok) {
      responseText.value = `No response text received for ${text}`;
      // throw new Error(`Server returned status ${response.status}`);
    } else {
      const data = await response.json();
      responseText.value = data?.response || 'No response text received.';
    }
    // Now speak the response
    speakTTS(responseText.value);
  } catch (error) {
    console.error('Error sending text to server:', error);
  }
}

// ----------------------------------------------------
// Speech Synthesis (TTS)
// ----------------------------------------------------
function speakTTS(text) {
  if (!('speechSynthesis' in window)) {
    console.warn('Speech Synthesis not supported in this browser.');
    return;
  }

  const utterance = new SpeechSynthesisUtterance(text);

  // Example: Set voice, language, pitch, etc., if desired
  // utterance.voice = speechSynthesis.getVoices()[0];
  // utterance.pitch = 1;
  // utterance.rate = 1;

  utterance.onstart = () => {
    isSpeaking.value = true;
    // Attempt to resume the AudioContext for TTS animations
    if (audioContext.state === 'suspended') {
      audioContext.resume();
    }
    /*
      NOTE: There's no direct standard method to obtain a raw audio stream from
      SpeechSynthesisUtterance in most browsers. If you want the real amplitude
      data from TTS output, you'd need advanced or experimental approaches
      (such as capturing system audio, or a custom TTS pipeline).
      Below, we'll simply approximate amplitude changes in the animation loop
      to mimic "speaking" behavior.
    */
  };

  utterance.onend = () => {
    isSpeaking.value = false;
  };

  speechSynthesis.speak(utterance);
}

// ----------------------------------------------------
// Fog Circle Animation Loop
// ----------------------------------------------------
function animateFogCircle() {
  // Use requestAnimationFrame to continuously update the fog circle's scale
  animationFrameId = requestAnimationFrame(animateFogCircle);

  let currentVolume = 0;

  // If listening, we use real microphone data
  if (isListening.value && microphoneSourceNode) {
    analyser.getByteTimeDomainData(dataArray);
    // Calculate an average amplitude from the time domain data
    let sum = 0;
    for (let i = 0; i < dataArray.length; i++) {
      const val = (dataArray[i] - 128) / 128; // center around 0
      sum += Math.abs(val);
    }
    const average = sum / dataArray.length;
    // Scale up somewhat for a better visual range
    currentVolume = average * 2; // adjust factor to taste
  }
  // If speaking, we do an approximate "random" fluctuation to simulate TTS output
  else if (isSpeaking.value) {
    // For demonstration, we'll fluctuate volume randomly in a certain range
    currentVolume = 0.1 + Math.random() * 0.2;
  }
  // If idle, we keep volume at 0 (the CSS handles gentle breathing)
  else {
    currentVolume = 0;
  }

  volumeLevel.value = currentVolume;

  // Now apply the scale transformation to the fog circle
  if (fogCircle.value) {
    // Base scale for the circle is 1, we'll add volumeLevel as an offset
    const scale = 1 + volumeLevel.value;
    fogCircle.value.style.transform = `scale(${scale})`;
  }
}
</script>

<style scoped>
/*
  Basic styling for the container, fog circle, and push-to-talk button.
  The "fog-circle" will have an idle breathing animation in CSS,
  augmented by JS-based scaling when isListening or isSpeaking is true.
*/

.fog-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
  position: relative;
}

/*
  The fog circle can be a radial gradient or a semi-transparent background
  with a "breathing" keyframe.
*/
.fog-circle {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  background: radial-gradient(circle, rgba(200,200,255,0.3), rgba(100,100,255,0.15));
  transition: transform 0.1s ease-out;
  /* Idle breathing animation */
  animation: idleBreathing 3s infinite ease-in-out;
}

/*
  Use a gentle scale from 0.95 to 1.05 to simulate breathing.
  JavaScript will override the transform in real-time if there's audio.
*/
@keyframes idleBreathing {
  0%   { transform: scale(0.95); }
  50%  { transform: scale(1.05); }
  100% { transform: scale(0.95); }
}

.ptt-button {
  margin-top: 1.5rem;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  cursor: pointer;
  border: none;
  border-radius: 8px;
  background-color: #667aff;
  color: #fff;
}

.ptt-button:hover {
  background-color: #5465dd;
}
</style>

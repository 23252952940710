<template>
  <div class="xs6 pa1">
    <w-input type="date" label-position="left" label="Date" outline v-model="date"/>
  </div>
  <div class="xs6 pa1">
    <w-input type="time" label-position="left" label="Time" outline v-model="time"/>
  </div>
  <div class="ma2">
    <w-select label-position="inside" multiple :items="[
      {
        label: 'Fever Syrup',
        icon: 'mdi mdi-medical-bag',
        value: 'fever-syrup'
      },{
        label: 'Cough Syrup',
        icon: 'mdi mdi-medical-bag',
        value: 'cough-syrup'
      },{
        label: 'Cold Syrup',
        icon: 'mdi mdi-medical-bag',
        value: 'cold-syrup'
      },{
        label: 'Pain Killer',
        icon: 'mdi mdi-medical-bag',
        value: 'pain-killer'
      },{
        label: 'Antibiotic',
        icon: 'mdi mdi-medical-bag',
        value: 'antibiotic'
      },{
        label: 'Nose Drop',
        icon: 'mdi mdi-medical-bag',
        value: 'nose-drop'
      },{
        label: 'Eye Drop',
        icon: 'mdi mdi-medical-bag',
        value: 'eye-drop'
      },{
        label: 'Ear Drop',
        icon: 'mdi mdi-medical-bag',
        value: 'ear-drop'
      },{
        label: 'Vitamin D',
        icon: 'mdi mdi-leaf',
        value: 'vitamin-d'
      },{
        label: 'Vitamin C',
        icon: 'mdi mdi-leaf',
        value: 'vitamin-c'
      },{
        label: 'Vitamin B',
        icon: 'mdi mdi-leaf',
        value: 'vitamin-b'
      },{
        label: 'Vitamin A',
        icon: 'mdi mdi-leaf',
        value: 'vitamin-a'
      },{
          label: 'probiotic',
          icon: 'mdi mdi-leaf',
          value: 'probiotic'
      },{
        label: 'Colic Drop',
        icon: 'mdi mdi-leaf',
        value: 'colic-drop'
    },{
        label: 'Multivitamin',
        icon: 'mdi mdi-leaf',
        value: 'multivitamin'
    },{
        label: 'Zinc',
        icon: 'mdi mdi-leaf',
        value: 'zinc'
    },{
        label: 'Iron',
        icon: 'mdi mdi-leaf',
        value: 'iron'
    },{
        label: 'Calcium',
        icon: 'mdi mdi-leaf',
        value: 'calcium'
    },{
        label: 'Paracetamol',
        icon: 'mdi mdi-leaf',
        value: 'paracetamol'
    },{
        label: 'Antacid',
        icon: 'mdi mdi-leaf',
        value: 'antacid'
    },{
        label: 'Antiallergic',
        icon: 'mdi mdi-leaf',
        value: 'antiallergic'
    },{
        label: 'Antifungal',
        icon: 'mdi mdi-leaf',
        value: 'antifungal'
    },{
        label: 'Antiviral',
        icon: 'mdi mdi-leaf',
        value: 'antiviral'
    },{
        label: 'Antiparasitic',
        icon: 'mdi mdi-leaf',
        value: 'antiparasitic'
    },{
        label: 'Antibacterial',
        icon: 'mdi mdi-leaf',
        value: 'antibacterial'
    },{
        label: 'Antiseptic',
        icon: 'mdi mdi-leaf',
        value: 'antiseptic'
    },{
        label: 'Anticancer',
        icon: 'mdi mdi-leaf',
        value: 'anticancer'
    },{
        label: 'Antidiabetic',
        icon: 'mdi mdi-leaf',
        value: 'antidiabetic'
    },{
        label: 'Antihypertensive',
        icon: 'mdi mdi-leaf',
        value: 'antihypertensive'
    },{
        label: 'Anticoagulant',
        icon: 'mdi mdi-leaf',
        value: 'anticoagulant'
    },{
        label: 'Antipsychotic',
        icon: 'mdi mdi-leaf',
        value: 'antipsychotic'
    },{
        label: 'Antidepressant',
        icon: 'mdi mdi-leaf',
        value: 'antidepressant'
    },{
        label: 'Antianxiety',
        icon: 'mdi mdi-leaf',
        value: 'antianxiety'
    }]"
      v-model="medications">
      <template #selection="{ item }">
        <w-tag
            class="mr2"
            v-for="(t, i) in item"
            :key="i"
            bg-color="grey-light4">
          <w-icon class="mr1">{{t.icon}}</w-icon>
          {{ t.label }}
        </w-tag>
      </template>
      <template #item="{ item }">
        <w-icon>{{ item.icon }}</w-icon>
        <span class="ml1">{{ item.label }}</span>
      </template>
      Select Medication
    </w-select>
  </div>
  <w-divider></w-divider>
  <div class="text-right">
      <w-button class="ma1" bg-color="secondary" icon="wi-cross" @click="onCancel"></w-button>
      <w-button class="ma1" bg-color="primary" icon="wi-check" :disabled="!valid" @click="onSubmit"></w-button>
  </div>
</template>
<script setup>
import {ref, defineEmits, computed, onMounted} from "vue";
import {extractHoursAndMinutesFromDate} from "@/shared/extract-hours-and-minutes-from-date.function";
import {toISOStringWithLocalTimezone} from "@/shared/to-iso-string-with-local-timezone.function";
//TODO: add marking to medicine and for supplements to show the difference
const medications = ref([])
const date = ref(new Date().toISOString().split('T')[0])
const time = ref(toISOStringWithLocalTimezone(new Date()).split('T')[1])

const $emit = defineEmits(['cancel', 'submit'])

const valid = computed(() => {
  return medications.value.length > 0
})

function onCancel() {
  $emit('cancel')
}

function onSubmit() {
  $emit('submit', medications.value.map(med => ({
     date: date.value,
     time: extractHoursAndMinutesFromDate(time.value),
     name: med
  })))
}

onMounted(()=> {
  medications.value = []
  date.value = new Date().toISOString().split('T')[0]
  time.value = toISOStringWithLocalTimezone(new Date()).split('T')[1]
})
</script>
export function toISOStringWithLocalTimezone(date) {
    const options = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false,
    };

    const localeString = date.toLocaleString('en-GB', options);
    const [day, month, year, hour, minute] = localeString.match(/\d+/g);

    return `${year}-${month}-${day}T${hour}:${minute}:00.000`;
}
import {GET, POST} from "@/services/base.http";

const auth_url = "/auth";

export function authenticationService() {

  const login = async (username, password) => {
      const response = await POST(`${auth_url}/login`, JSON.stringify({username, password}), {
          credentials: 'include',
      })
      if (!response.ok) {
          try {
              const responseJson = await response.json();
              return {
                  status: response.status,
                  message: Array.isArray(responseJson.message) ? responseJson.message.join(', ') : responseJson.message,
              };
          } catch (error) {
              throw new Error(`Authentication Service - login failed: ${error.message}`);
          }
      }
      return await response.json();
  };

  const signup = async (username, password) => {
        const response = await POST(`${auth_url}/signup`,JSON.stringify({username, password}),{
            credentials: 'include',
        })
        if (!response.ok) {
            try {
                const responseJson = await response.json();
                return {
                        status: response.status,
                        message: Array.isArray(responseJson.message) ? responseJson.message.join(', ') : responseJson.message,
                    };
            } catch (error) {
                throw new Error(`Authentication Service - signup failed: ${error.message}`);
            }
        }
        return await response.json();
  }

  const logout = async (id,jwt) => {
    const response = await GET(`${auth_url}/logout/${id}`,{
        headers: jwt ? {
            'Authorization': `Bearer ${jwt}`,
        } : undefined
    })
    if (!response.ok) {
        try {
            const responseJson = await response.json();
            return {
                    status: response.status,
                    message: Array.isArray(responseJson.message) ? responseJson.message.join(', ') : responseJson.message,
                };
        } catch (error) {
            throw new Error(`Authentication Service - logout failed: ${error.message}`);
        }
    }
    return true;
  }

  const refresh = async (jwt,refreshToken) => {
    const response = await GET(`${auth_url}/refresh`,{
        headers: jwt && refreshToken ? {
            'Authorization': `Bearer ${jwt}`,
            'x-refresh-token': refreshToken
        } : undefined
    })
    if (!response.ok) {
        try {
            const responseJson = await response.json();
            return {
                    status: response.status,
                    message: Array.isArray(responseJson.message) ? responseJson.message.join(', ') : responseJson.message,
                };
        } catch (error) {
            throw new Error(`Authentication Service - refresh failed: ${error.message}`);
        }
    }
    return await response.json();
  }

    const retry = async (run) => {
        const handleRetry = async () => {
            const response = await refresh();
            if (response.ok) {
                return await run();
            } else {
                throw new Error(`Authentication Service - retry failed: ${response.message}`);
            }
        };

        try {
            const result = await run();
            if (result.status === 401) {
                return await handleRetry();
            }
            return result;
        } catch (error) {
            if (error.status === 401) {
                return await handleRetry();
            } else {
                throw new Error(`Authentication Service - retry failed: ${error.message}`);
            }
        }
    };

  return {
    login,
    logout,
    signup,
    refresh,
    retry
  };
}
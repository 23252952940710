import {DELETE, GET, POST} from "@/services/base.http";
import { authenticationService } from "@/services/authentication.service";

export function getRepo(key) {
    return (function (){

        const retry = authenticationService().retry

        async function fetchRecords(accountId){
            const response = await retry(()=>GET(`/api/${key}/${accountId}`))
            if(!response.ok) {
                console.error(`Error fetching ${key} records: ${response.status}`)
                return {
                    data: []
                }
            }
            const data = await response.json()
            return {
                data
            }
        }

        async function addRecord(accountId,record){
            const response = await retry(()=>POST(`/api/${key}/${accountId}`,JSON.stringify(record)))
            if(!response.ok) {
                console.error(`Error adding ${key} record: ${response.status}`)
                return {
                    data: null
                }
            }
            const data = await response.json()
            return {
                data
            }
        }

        async function addRecords(accountId,records){
            const response = await retry(()=>POST(`/api/${key}/${accountId}/bulk`,JSON.stringify(records)))
            if(!response.ok) {
                console.error(`Error adding ${key} records: ${response.status}`)
                return {
                    data: []
                }
            }
            const data = await response.json()
            return {
                data
            }
        }

        async function deleteRecord(accountId,record){
            const response = await retry(()=>DELETE(`/api/${key}/${accountId}/${record.id}`))
            if(!response.ok) {
                console.error(`Error deleting ${key} record: ${response.status}`)
                return {
                    data: null
                }
            }
            const data = await response.json()
            return {
                data
            }

        }

        return {
            fetchRecords,
            addRecord,
            addRecords,
            deleteRecord
        }
    })()
}
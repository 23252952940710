import {GET} from "@/services/base.http";

const auth_url = "/auth";

export function userService(){
    const get = async ()=>{
        const response = await GET(`${auth_url}/user/me`)
        return response.json()
    }
    return {
        get,
    }
}
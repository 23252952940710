import {computed, ref} from "vue";
import {useUserStore} from "@/stores/user.store";

export function baseStore(repository) {
    const userStore = useUserStore();
    const data = ref([])

    async function fetchRecords() {
        try {
            const accountId = userStore?.user?.accountId;
            const response = await repository.fetchRecords(accountId);
            data.value = response.data;
            return response.data;
        } catch (error) {
            console.log("fetchRecords", error)
        }
        return null
    }

    async function addRecord(record) {
        try {
            const accountId = userStore?.user?.accountId;
            const response = await repository.addRecord(accountId,record)
            if(response.data){
                data.value.push(response.data);
            } else {
                console.error("failed to add record", response)
            }
            return response.data;
        } catch (error) {
            console.log("addRecord", error);
        }
        return null
    }

    async function deleteRecord(record) {
        try {
            const accountId = userStore?.user?.accountId;
            const response = await repository.deleteRecord(accountId,record)
            data.value = data.value.filter(r => r.id !== record.id);
            return response.data;
        } catch (error) {
            console.log("deleteRecord", error);
        }
        return null
    }

    async function addRecords(records){
        try {
            const accountId = userStore?.user?.accountId;
            const response = await repository.addRecords(accountId,records)
            data.value.push(...response.data)
            return response.data;
        } catch (error) {
            console.log("addRecords", error);
        }
        return null
    }

    const lastRecord = computed(() => {
        return data.value.reduce((prev, current) => {
            return new Date(`${prev.date}T${prev.time}:00.000Z`).getTime() > new Date(`${current.date}T${current.time}:00.000Z`).getTime() ? prev : {...current}
        }, {data: '1970-01-01', time: '00:00'})
    })

    const todayRecords = computed(() => {
        const today = new Date().toISOString().split('T')[0]
        return data.value.filter(r => r.date === today)
    })


    return {
        data,
        fetchRecords,
        addRecord,
        deleteRecord,
        addRecords,
        lastRecord,
        todayRecords
    }
}
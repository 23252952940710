import {defineStore} from "pinia";
import {ref} from "vue";
import {useUserStore} from "@/stores/user.store";
import {GET, POST, PUT} from "@/services/base.http";

export function settingsRepository() {

    async function fetch(accountId) {
        const response = await GET(`/api/settings/${accountId}`)
        if (response.ok) {
            return response.json()
        }
        throw new Error(`Settings Repository - fetch failed: ${response.statusText}`)
    }

    async function set(accountId, settings) {
        const response = await POST(`/api/settings/${accountId}`, JSON.stringify(settings))
        if (response.ok) {
            return response.json()
        }
        throw new Error(`Settings Repository - set failed: ${response.statusText}`)
    }

    async function update(accountId, settings) {
        const response = await PUT(`/api/settings/${accountId}`, JSON.stringify(settings))
        if (response.ok) {
            return response.json()
        }
        throw new Error(`Settings Repository - update failed: ${response.statusText}`)
    }



    return {
        fetch,
        set,
        update
    }
}

const repository =  settingsRepository()

export const useSettingsStore = defineStore('settings', ()=> {
    const data = ref({
        dayStartTime: 0
    })

    const loading = ref(false)
    const userStore = useUserStore()

    async function load() {
        try {
            loading.value = true
            const accountId = userStore?.user?.accountId;
            const response = await repository.fetch(accountId)
            data.value = {
                dayStartTime: response.dayStartTime
            }
        } catch (error) {
            console.log("settings load", error)
        } finally {
            loading.value = false
        }
    }

    async function update(settings) {
        try {
            loading.value = true
            const accountId = userStore?.user?.accountId;
            const response = await repository.update(accountId, settings)
            data.value = {
                dayStartTime: response.dayStartTime
            }
        } catch (error) {
            console.log("settings update", error)
        } finally {
            loading.value = false
        }
    }

    return {
        data,
        loading,
        load,
        update,
    }
})
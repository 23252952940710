<template>
  <w-card title="Weight For Age" title-class="grey-light6--bg">
    <template #title>
      <div class="title5">Weight For Age</div>
      <div class="spacer"></div>
      <span class="ml2">
        <w-icon xl :class="allMonthesAreShown ? 'ma1 grey-light1' : 'btn ma1'" @click="addMonth" :disabled="allMonthesAreShown">
          mdi mdi-minus
        </w-icon>
      </span>
      <span class="ml2">
        <w-icon xl :class="onlyOneMonthIsShown ? 'ma1 grey-light1' : 'btn ma1'" @click="removeMonth" :disabled="onlyOneMonthIsShown">
          mdi mdi-plus
        </w-icon>
      </span>
      <span class="ml2">
        <w-icon xl :class="firstMonthIsShow ? 'ma1 grey-light1' : 'btn ma1'" @click="moveLeft" :disabled="firstMonthIsShow">
          mdi mdi-arrow-left
        </w-icon>
      </span>
      <span class="ml2">
        <w-icon xl :class="lastMonthIsShow ? 'ma1 grey-light1' : 'btn ma1'" @click="moveRight" :disabled="lastMonthIsShow">
          mdi mdi-arrow-right
        </w-icon>
      </span>
    </template>
    <div style="height: 100vh;" class="justify-center pa5">
      <apexchart
          type="line"
          height="80%"
          :series="chartSeries"
          :options="chartOptions"
      />
    </div>
  </w-card>
</template>

<script setup>
import { defineProps, computed, ref } from 'vue';
import dayjs from 'dayjs';
import Apexchart from "vue3-apexcharts";

// A flag used to decide whether to use corrected age (for preterm babies)
const correctedAge = ref(false);

/**
 * Expected props:
 * - percentileData: Array of 14 lines. Each line is an object with:
 *     { percentile: String, data: [ { month, weight }, ... ] }
 * - actualMeasurements: Array of measurements: { date: String, weight: Number }
 * - birthDate: String (in a format dayjs can parse)
 * - gestationalWeeksAtBirth: Number (default 40, full-term)
 */
const props = defineProps({
  percentileData: {
    type: Array,
    default: () => []
  },
  actualMeasurements: {
    type: Array,
    default: () => []
  },
  birthDate: {
    type: String,
    required: true
  },
  gestationalWeeksAtBirth: {
    type: Number,
    default: 40
  }
});

/**
 * Convert "month" (0..12) to weeks.
 * (1 month ≈ 4.345 weeks)
 */
function monthToWeeks(month) {
  return month * 4.345;
}

/**
 * For babies born preterm, compute the corrected age (in weeks)
 */
function correctedWeeksFromBirth(dateStr, birthDateStr, gestationalWeeksAtBirth) {
  const birth = dayjs(birthDateStr);
  const current = dayjs(dateStr);
  const diffDays = current.diff(birth, 'day');
  const diffWeeks = diffDays / 7;
  const correctionOffset = 40 - gestationalWeeksAtBirth;
  const corrected = diffWeeks - correctionOffset;
  return Math.max(corrected, 0);
}

/**
 * Convert a measurement date to “weeks from birth”
 */
function dateToWeeksFromBirth(dateStr, birthDateStr) {
  const birth = dayjs(birthDateStr);
  const current = dayjs(dateStr);
  const diffDays = current.diff(birth, 'day');
  const diffWeeks = diffDays / 7;
  return Math.floor(diffWeeks);
}

/**
 * Create the chart series.
 * - Percentile lines (14 series) use the month-to-weeks conversion.
 * - The baby’s actual measurements are shown as a scatter plot.
 */
const chartSeries = computed(() => {
  // Percentile series
  const percentileSeries = props.percentileData.map(line => {
    const seriesData = line.data.map(pt => ({
      x: monthToWeeks(pt.month),
      y: pt.weight
    }));
    return {
      name: `${line.percentile} percentile`,
      type: 'line',
      data: seriesData
    };
  });
  // Baby’s measurements
  const measurementSeriesData = props.actualMeasurements.map(m => {
    if (correctedAge.value && props.gestationalWeeksAtBirth < 37) {
      const correctedWks = correctedWeeksFromBirth(
          m.date,
          props.birthDate,
          props.gestationalWeeksAtBirth
      );
      return { x: correctedWks, y: m.weight, date: m.date };
    } else {
      return { x: dateToWeeksFromBirth(m.date, props.birthDate), y: m.weight, date: m.date };
    }
  });
  const babySeries = {
    name: 'Weight',
    type: 'scatter',
    data: measurementSeriesData,
  };

  return [
    ...percentileSeries,
    babySeries
  ];
});

/* ----- X-AXIS WINDOW STATE AND NAVIGATION FUNCTIONS ----- */

// Maintain the current visible x-axis range (in weeks)
// Default: from week 0 to week corresponding to 12 months (≈52 weeks)
const xMin = ref(0);
const xMax = ref(monthToWeeks(12));

// Compute the maximum available week based on the provided data.
// This helps in deciding when to disable the "add" and "move right" buttons.
const maxDataWeek = computed(() => {
  let maxWeek = 0;
  // Check percentile data
  props.percentileData.forEach(line => {
    line.data.forEach(pt => {
      const week = monthToWeeks(pt.month);
      if (week > maxWeek) maxWeek = week;
    });
  });
  // Check actual measurements
  props.actualMeasurements.forEach(m => {
    let week = (correctedAge.value && props.gestationalWeeksAtBirth < 37)
        ? correctedWeeksFromBirth(m.date, props.birthDate, props.gestationalWeeksAtBirth)
        : dateToWeeksFromBirth(m.date, props.birthDate);
    if (week > maxWeek) maxWeek = week;
  });
  return maxWeek;
});

// Button disabling computed properties
const allMonthesAreShown = computed(() => xMax.value >= maxDataWeek.value);

const onlyOneMonthIsShown = computed(() => (xMax.value - xMin.value) <= monthToWeeks(1));

const firstMonthIsShow = computed(() => xMin.value <= 0);

const lastMonthIsShow = computed(() => xMax.value >= maxDataWeek.value);

// Add one month (≈4.345 weeks) to the view if not already showing all data.
function addMonth() {
  if (!allMonthesAreShown.value) {
    xMax.value = Math.min(xMax.value + monthToWeeks(1), maxDataWeek.value);
  }
}

// Remove one month from the view (but keep at least one month visible).
function removeMonth() {
  if (!onlyOneMonthIsShown.value) {
    xMax.value = Math.max(xMax.value - monthToWeeks(1), xMin.value + monthToWeeks(1));
  }
}

// Pan the view window to the left (if not already at week 0)
function moveLeft() {
  const shift = monthToWeeks(1);
  if (xMin.value - shift >= 0) {
    xMin.value -= shift;
    xMax.value -= shift;
  } else {
    // If the shift would move below 0, set xMin to 0.
    const diff = xMin.value; // current xMin is less than shift
    xMin.value = 0;
    xMax.value = Math.max(xMax.value - diff, monthToWeeks(12));
  }
}

// Pan the view window to the right (if not already at the maximum available week)
function moveRight() {
  const shift = monthToWeeks(1);
  if (xMax.value + shift <= maxDataWeek.value) {
    xMin.value += shift;
    xMax.value += shift;
  }
}

/* ----- Chart Options (Reactive) ----- */

// Make chart options a computed property so that x-axis changes update the chart.
const chartOptions = computed(() => ({
  chart: {
    toolbar: {
      show: true,
      tools: {
        download: true,
      },
      export: {
        csv: {
          filename: 'GrowthChart',
          columnDelimiter: ',',
          headerCategory: 'Weeks',
          headerValue: 'Weight'
        },
        svg: { filename: 'GrowthChart' },
        png: { filename: 'GrowthChart' }
      }
    },
    zoom: { enabled: false },
  },
  xaxis: {
    type: 'numeric',
    min: xMin.value,
    max: xMax.value,
    // Dynamically calculate tick count based on the current window width (in months)
    tickAmount: Math.ceil((xMax.value - xMin.value) / monthToWeeks(1)) + 1,
    labels: {
      formatter: (val) => {
        // Convert weeks to months (approximate)
        const months = val / 4.345;
        return `M${Math.round(months)}`;
      }
    },
  },
  yaxis: {
    title: { text: 'Weight (kg)' },
    labels: { formatter: (val) => val.toFixed(1) }
  },
  dataLabels: {
    enabled: true,
    offsetY: -10,
    style: { fontSize: '12px', fontWeight: 'bold' },
    background: { enabled: false },
    formatter: function (val, opts) {
      // Only show the series name at the last data point
      if (opts.dataPointIndex === opts.w.config.series[opts.seriesIndex].data.length - 1) {
        return opts.w.config.series[opts.seriesIndex].name;
      }
      return '';
    },
  },
  stroke: { curve: 'straight', width: 1 },
  colors: ['red','orange','green','orange','red','black'],
  markers: { size: [2,2,2,2,2,5] },
  legend: { position: 'top' },
  tooltip: {
    x: {
      formatter: (val, { seriesIndex, dataPointIndex, w }) => {
        const seriesName = w.config.series[seriesIndex].name;
        if (seriesName === 'Weight') {
          // Show the original date for the baby's measurements.
          const origDate = w.config.series[seriesIndex].data[dataPointIndex].date;
          return dayjs(origDate).format('YYYY-MM-DD');
        }
        return `Week ${val}`;
      }
    },
    y: { formatter: (val) => `${val} kg` }
  }
}));
</script>

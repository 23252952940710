<template>
  <div>
    <w-toolbar>
      <div class="btn" @click="onChildNavigation('/')">
        <NutrinoLogo :width=50 :height=50></NutrinoLogo>
      </div>
      <div class="btn ml-5" @click="onChildNavigation('/')">
        <div class="title3 mr3">Baby Neutrino</div>
      </div>
      <div class="spacer"></div>
      <div v-if="isLoggedIn">

<!--      <w-button icon="mdi mdi-cog" text lg class="ml3" @click="onChildNavigation('settings')"></w-button>-->
<!--      <w-button icon="mdi mdi-baby" text lg class="ml3" @click="onChildNavigation('account')"></w-button>-->
      <w-button icon="mdi mdi-microphone" text lg class="ml3" @click="onChildNavigation('ai')"></w-button>
      <w-menu
          align-right
          custom
          hide-on-menu-click
          persistent
          shadow>
        <template #activator="{ on }">
          <w-button icon="mdi mdi-menu" text lg class="ml3" v-on="on"></w-button>
        </template>
        <w-list
            class="base-color--bg"
            v-model="selectedPage"
            :items="[{
               label: 'Home',
               route: '/',
             },{
               label: 'Profile',
               route: '/profile',
             },{
               label: 'App Settings',
               route: '/settings',
             },{
               label: 'Baby Details',
               route: '/account',
             },{
               label: 'Weight For Age',
               route: '/chart',
             },
             // {
             //   label: 'Length/Height For Age',
             //   route: '/chart',
             // },{
             //   label: 'Head Circumference For Age',
             //   route: '/chart',
             // },{
             //   label: 'Recommendations',
             //   route: '/recommendations',
            // },
            {
               label: 'Logout',
               route: '/logout',
            }]"
            item-class="px8"
            nav>
        </w-list>
      </w-menu>
      </div>
    </w-toolbar>
  </div>
  <div id="app">
    <router-view @navigateTo="onChildNavigation"></router-view>
  </div>

</template>
<script setup>
import { ref, watch} from 'vue'
import { useRouter } from 'vue-router'
import NutrinoLogo from "@/components/NutrinoLogo.vue";
import {useFoodStore} from "@/stores/food.store";
import {useWeightStore} from "@/stores/weight.store";
import {useHeightStore} from "@/stores/height.store";
import {useHeadSizeStore} from "@/stores/head-size.store";
import {useDiaperStore} from "@/stores/diaper.store";
import {useMedicationStore} from "@/stores/medication.store";
import {useUserStore} from "@/stores/user.store";
import {storeToRefs} from "pinia";
import {useBabyStore} from "@/stores/baby.store";
import {useSettingsStore} from "@/stores/settings.store";

const babyStore = useBabyStore()
const foodStore = useFoodStore()
const weightStore = useWeightStore()
const heightStore = useHeightStore()
const headSizeStore = useHeadSizeStore()
const diaperStore = useDiaperStore()
const medicationStore = useMedicationStore()
const userStore = useUserStore()
const settingsStore = useSettingsStore()

const { isLoggedIn } = storeToRefs(userStore)
const router = useRouter()

const dataLoaded = ref(false)

watch(isLoggedIn, (newVal) => {
  if (newVal && !dataLoaded.value) {
    babyStore.load()
    settingsStore.load()
    foodStore.fetchRecords();
    weightStore.fetchRecords();
    heightStore.fetchRecords();
    headSizeStore.fetchRecords();
    diaperStore.fetchRecords();
    medicationStore.fetchRecords();
    dataLoaded.value = true;
    console.log('data loaded')
  }
});
if(!isLoggedIn.value){
  userStore.load()
}
const selectedPage = ref('Nursing Records');

function onChildNavigation(page) {
  console.log('navigate to', page)
  router.replace(page);

}
</script>

<style scoped>
.container {
  max-width: 800px;
}
</style>

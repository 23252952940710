<template>
  <w-card class="mt10">
    <template #title>
      <w-toolbar :class="`${constants.colors.food}--bg`">
        <w-icon class="mr2" md>{{constants.icons.food}}</w-icon>
        <span>Food Intake Details</span>
        <div class="spacer"></div>
        <w-menu align-left arrow show-on-hover>
          <template #activator="{ on }">
            <w-icon v-on="on" xl class="ml2 md btn">mdi mdi-tray-arrow-down</w-icon>
          </template>
          <w-list :items="[
                  { icon: 'mdi mdi-file-multiple-outline', onClick: downloadPdf },
                  { icon: 'mdi mdi-microsoft-excel', onClick: downloadCsv },]"
                  nav color="primary">
            <template #item="{ item }">
              <w-icon xl class="ml-10 btn" @click="item.onClick">{{ item.icon }}</w-icon>
            </template>
          </w-list>
        </w-menu>

        <w-icon xl class="ml2 md btn" @click="showDialog=true">mdi mdi-pencil-plus-outline</w-icon>
        <w-icon class="ml2 md btn" @click="emit('navigateTo','/')">{{constants.icons.back}}</w-icon>
      </w-toolbar>
    </template>
    <ExpendingTableComponent
        :value="data"
        :aggregate-func="aggregateRecords"
        :aggregation-headers="aggregated_table_headers"
        :headers="raw_table_headers"
        :filter-by="filterRecordsByDate"
        :showAddButton="false"
        @delete="onDelete">
    </ExpendingTableComponent>

    <form-dialog-component
        title="New Food"
        :icon = "constants.icons.food"
        :color = "constants.colors.food"
        :show="showDialog">
      <FoodForm @submit="onFoodFormSubmit" @cancel="showDialog=false"></FoodForm>
    </form-dialog-component>
  </w-card>
</template>
<script setup>

import {constants} from "@/shared/constants";
import {useFoodStore} from "@/stores/food.store";
import ExpendingTableComponent from "@/components/ExpendingTableComponent.vue";
import {storeToRefs} from "pinia";
import FoodForm from "@/components/FoodForm.vue";
import FormDialogComponent from "@/components/FormDialogComponent.vue";
import {computed, defineEmits, ref} from "vue";
import {useSettingsStore} from "@/stores/settings.store";
import {Parser} from "@json2csv/plainjs";
import FileSaver from "file-saver";
import { useGeneratePDF } from '@/services/reports.service';


const emit = defineEmits(['navigateTo'])
const showDialog = ref(false)
const foodStore = useFoodStore()
const settingsStore = useSettingsStore()
const { generatePDF } = useGeneratePDF();

const { data } = storeToRefs(foodStore)
const { data: settings } = storeToRefs(settingsStore)

const aggregated_table_headers = computed(()=>{
  const headers = [
    {label: 'Date', key: 'date'},
    {label: 'Total', key: 'totalAmount'},
  ]
  data.value.some(r => r.feedingMethod === 'bottle') && headers.push(
      {label: 'Bottle Total', key: 'totalBottle'},
      {label: 'Bottle Average', key: 'bottleAverageAmount'},
      {label: 'Bottle Meals', key: 'bottleMeals'},
  )
  data.value.some(r => r.feedingMethod === 'tube') && headers.push(
      {label: 'Tube Total', key: 'totalTube'},
      {label: 'Tube Average', key: 'tubeAverageAmount'},
      {label: 'Tube Meals', key: 'tubeMeals'},
  )
  data.value.some(r => r.feedingMethod === 'spoon') && headers.push(
      {label: 'Spoon Meals', key: 'spoonMeals'},
  )
  return headers
})
const raw_table_headers =[
  {label: 'Date', key: 'date'},
  {label: 'Time', key: 'time'},
  {label: 'Amount', key: 'amount'},
  {label: 'Willingness', key: 'willingness'},
  {label: 'Emissions', key: 'emissions'},
  {label: 'Method', key: 'feedingMethod'},
]

function findCurrentDate(acc, currentTS) {
  return acc.find(r => {
    return r.maxDate >= currentTS && r.minDate <= currentTS;
  });
}

function updateDateObject(curDate, current) {
  curDate.totalAmount += current.amount;
  curDate.totalBottle += current.feedingMethod === 'bottle' ? current.amount : 0;
  curDate.bottleMeals += current.feedingMethod === 'bottle' ? 1 : 0;
  curDate.bottleAverageAmount = Math.round((curDate.totalBottle / curDate.bottleMeals) * 100) / 100;

  curDate.totalTube += current.feedingMethod === 'tube' ? current.amount : 0;
  curDate.tubeMeals += current.feedingMethod === 'tube' ? 1 : 0;
  curDate.tubeAverageAmount = Math.round((curDate.totalTube / curDate.tubeMeals) * 100) / 100;

  curDate.totalSpoon += current.feedingMethod === 'spoon' ? current.amount : 0;
  curDate.spoonMeals += current.feedingMethod === 'spoon' ? 1 : 0;
  curDate.spoonAverageAmount = Math.round((curDate.totalSpoon / curDate.spoonMeals) * 100) / 100;

  curDate.numberOfMeals += 1;
  curDate.averageAmount = Math.round((curDate.totalAmount / curDate.numberOfMeals) * 100) / 100;
}

function createNewDateObject(current) {
  const minDate = new Date(`${current.date}T00:00:00`).addHours(settings.value.dayStartTime).getTime();
  const  maxDate = new Date(`${current.date}T23:59:59`).addHours(settings.value.dayStartTime).getTime();
  let currentTS = new Date(`${current.date}T${current.time}:00`);

  if(!(maxDate >= currentTS.getTime())){
    currentTS = currentTS.addDays(1)
  } else if(!(minDate <= currentTS.getTime())){
    currentTS = currentTS.addDays(-1)
  }

  return {
    maxDate: new Date(`${currentTS.format('YYYY-MM-DD')}T23:59:59`).addHours(settings.value.dayStartTime),
    minDate: new Date(`${currentTS.format('YYYY-MM-DD')}T00:00:00`).addHours(settings.value.dayStartTime),
    date: currentTS.format('YYYY-MM-DD'),
    totalAmount: current.amount,
    totalBottle: current.feedingMethod === 'bottle' ? current.amount : 0,
    bottleMeals: current.feedingMethod === 'bottle' ? 1 : 0,
    bottleAverageAmount: current.feedingMethod === 'bottle' ? current.amount : 0,
    totalTube: current.feedingMethod === 'tube' ? current.amount : 0,
    tubeMeals: current.feedingMethod === 'tube' ? 1 : 0,
    tubeAverageAmount: current.feedingMethod === 'tube' ? current.amount : 0,
    totalSpoon: current.feedingMethod === 'spoon' ? current.amount : 0,
    spoonMeals: current.feedingMethod === 'spoon' ? 1 : 0,
    spoonAverageAmount: current.feedingMethod === 'spoon' ? current.amount : 0,
    averageAmount: current.amount,
    numberOfMeals: 1,
  };
}

function aggregateRecords(data) {
  return data.reduce((acc, current) => {
    const currentTS = new Date(`${current.date}T${current.time}:00`).getTime();
    const curDate = findCurrentDate(acc, currentTS);
    if (curDate) {
      updateDateObject(curDate, current);
    } else {
      const dateObj = createNewDateObject(current)
      acc.push(dateObj);
    }
    return acc;
  }, [])
}
function filterRecordsByDate(records, item) {
  if(records){
    const filteredDateMinimum = new Date(`${item.date} 00:00:00`).addHours(settings.value.dayStartTime);
    const filteredDateMaximum = new Date(`${item.date} 23:59:59`).addHours(settings.value.dayStartTime);
    return records.filter(r => {
      const recordTS = new Date(`${r.date} ${r.time}`).getTime();
      return recordTS >= filteredDateMinimum.getTime() && recordTS <= filteredDateMaximum.getTime();
    }).map(r => {
      return {
        ...r,
        control: 'control',
        willingness: r.willingness === 1 ? '😡' :
                r.willingness === 2 ? '😠' :
                    r.willingness === 3 ? '😐' :
                        r.willingness === 4 ? '😊' :
                            r.willingness === 5 ? '🙂' : '🤢',
      }
    }).sort((a, b) => {
      return new Date(`${b.date} ${b.time}`).getTime() - new Date(`${a.date} ${a.time}`).getTime();
    });
  } else {
    return [];
  }
}

function onFoodFormSubmit(value) {
  foodStore.addRecord(value)
  showDialog.value = false
  console.log('Food Form Submitted:', value)
}

function onDelete(item) {
  foodStore.deleteRecord(item)
  console.log('Food Record Deleted:', item)
}
function downloadCsv(){
  console.log('PDF')
  try {
    const exportData = data.value.map(r => {
      return {
        Date: r.date || '',
        Time: r.time || '',
        Amount: r.amount || '',
        Method: r.feedingMethod || '',
        Duration: r.duration || 0,
        Willingness: r.willingness || '',
        Emissions: r.emissions || '',
        Notes: r.notes || '',
      }
    });
    const parser = new Parser();
    const csv = parser.parse(exportData);
    const header = `# This CSV file was generated by Baby Nutrino at ${new Date().toString()}\n`;
    console.log(header + csv);

    const blob = new Blob([header + csv], {type: 'text/csv;charset=utf-8'});
    FileSaver.saveAs(blob, 'food-intake.csv');
  } catch (err) {
    console.error(err);
  }
}


function downloadPdf() {
  const pdfData = data.value.reduce((acc, current) => {
    const currentTS = new Date(`${current.date}T${current.time}:00`).getTime();
    const curDate = findCurrentDate(acc, currentTS);
    if (curDate) {
      curDate.data.push({
        Date: current.date || '',
        Time: current.time || '',
        Amount: current.amount || '',
        Method: current.feedingMethod || '',
        Duration: current.duration || 0,
        Willingness: current.willingness || '',
        Emissions: current.emissions || '',
        Notes: current.notes || '',
      });
    } else {
      const minDate = new Date(`${current.date}T00:00:00`).addHours(settings.value.dayStartTime).getTime();
      const maxDate = new Date(`${current.date}T23:59:59`).addHours(settings.value.dayStartTime).getTime();
      let currentTS = new Date(`${current.date}T${current.time}:00`);

      if (!(maxDate >= currentTS.getTime())) {
        currentTS = currentTS.addDays(1)
      } else if (!(minDate <= currentTS.getTime())) {
        currentTS = currentTS.addDays(-1)
      }
      acc.push({
        date: currentTS.format('YYYY-MM-DD'),
        minDate: new Date(`${currentTS.format('YYYY-MM-DD')}T00:00:00`).addHours(settings.value.dayStartTime),
        maxDate: new Date(`${currentTS.format('YYYY-MM-DD')}T23:59:59`).addHours(settings.value.dayStartTime),
        data: [{
          Date: current.date || '',
          Time: current.time || '',
          Amount: current.amount || '',
          Method: current.feedingMethod || '',
          Duration: current.duration || 0,
          Willingness: current.willingness || '',
          Emissions: current.emissions || '',
          Notes: current.notes || '',
        }],
      });
    }
    return acc;
  }, [])

  generatePDF(pdfData, (bucketData) => {
    // Determine which feeding methods are present in the data.
    const methods = [...bucketData.reduce((acc, current) => {
      acc.add(current.Method);
      return acc;
    }, new Set())];

    // If more than one method is present, provide detailed breakdowns.
    if (methods.length > 1) {
      // Reduce the data into an object that holds overall total and
      // per-method totals and counts.
      const agg = bucketData.reduce((acc, current) => {
        // Overall total intake
        acc["Total Intake"] += current.Amount;
        // Create an entry for the method if it doesn't exist
        if (!acc[current.Method]) {
          acc[current.Method] = { intake: 0, meals: 0 };
        }
        // Add current amount and increment meal count for the method.
        acc[current.Method].intake += current.Amount;
        acc[current.Method].meals += 1;
        return acc;
      }, { "Total Intake": 0 });

      // Start building the results array.
      const result = [{
        "Measurement": "Total Intake",
        "Value": agg["Total Intake"]
      }];

      // Use a fixed order for display.
      const order = ['bottle', 'tube', 'spoon'];
      const orderedMethods = order.filter(m => methods.includes(m));

      // For each method, add detailed aggregation fields.
      orderedMethods.forEach(method => {
        const methodData = agg[method];
        const capitalized = method.charAt(0).toUpperCase() + method.slice(1);
        result.push({
          "Measurement": `Total ${capitalized} Intake`,
          "Value": methodData.intake
        });
        result.push({
          "Measurement": `${capitalized} Meals`,
          "Value": methodData.meals
        });
        result.push({
          "Measurement": `${capitalized} Average Meal`,
          "Value": methodData.meals
              ? Math.round((methodData.intake / methodData.meals) * 100) / 100
              : 0
        });
      });
      return result;
    }
    // Else if only one method is present, use a simpler aggregation.
    else {
      const aggregationValue = bucketData.reduce((acc, current) => {
        return {
          "Total Intake": acc["Total Intake"] + current.Amount,
          "Meals": acc["Meals"] + 1,
          // We'll calculate the average after finishing the reduction.
          "Average Meal": 0,
        };
      }, {
        "Total Intake": 0,
        "Meals": 0,
        "Average Meal": 0,
      });
      aggregationValue["Average Meal"] = aggregationValue["Meals"]
          ? Math.round((aggregationValue["Total Intake"] / aggregationValue["Meals"]) * 100) / 100
          : 0;
      return [{
        "Measurement": "Total Intake",
        "Value": aggregationValue["Total Intake"]
      },{
        "Measurement": "Meals",
        "Value": aggregationValue["Meals"]
      },{
        "Measurement": "Average Meal",
        "Value": aggregationValue["Average Meal"]
      }];
    }
  })
}

</script>
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import {logoBase64} from "@/assets/base64.logo";

export function getReportsService() {
    function createCsv(){

    }
    return {
        createCsv,
    }
}


export function useGeneratePDF() {
    const generatePDF = (jsonData,aggregate) => {
        const doc = new jsPDF();

        jsonData.forEach((bucket, index) => {
            const { date, data } = bucket;

            const headers = [...data.reduce((acc, item) => {
                return acc.union(new Set(Object.keys(item)));
            }, new Set())].filter(header => header !== 'id');

            // Add header with logo, page number, and bucket date
            if (index !== 0) doc.addPage();

            doc.addImage(logoBase64, 'PNG', 10, 10, 20, 20, { url: 'https://baby-nutrino.com' }); // Add logo on the left
            doc.link(10, 10, 20, 20, { url: 'https://baby-nutrino.com' }); // Make the logo area clickable

            doc.setFontSize(10);
            doc.text(`Page ${index + 1}`, 105, 20, null, null, 'center'); // Page number at center
            doc.text(`Date: ${date}`, 190, 20, null, null, 'right'); // Bucket date on the right

            // Add table with current bucket data
            const tableData = data.map(item => Object.values(item).filter(value => value !== item.id));
            doc.autoTable({
                head: [headers],
                body: tableData,
                startY: 40,
            });

            if(aggregate) {
                const aggregateData = aggregate(data)
                const aggregateHeaders = [...aggregateData.reduce((acc, item) => {
                    return acc.union(new Set(Object.keys(item)));
                }, new Set())].filter(header => header !== 'id');
                const aggregateValues = aggregateData.map(item => Object.values(item));
                doc.autoTable({
                    head: [aggregateHeaders],
                    body: aggregateValues,
                });
            }

        });

        // Save the PDF
        doc.save('BabyData.pdf');
    };

    return { generatePDF };
}

<template>
  <w-form @submit="submitForm">
    <w-flex wrap>
      <SpacedRow>
        <div class="title2 mb12 mt12  ">
          Settings
        </div>
      </SpacedRow>
      <spaced-row>
        <div>
          Day Start Time
        </div>
      </spaced-row>
      <spaced-row>
        <div class="justify-center text-center">
          {{ new Date('1970-01-01 00:00:0000').addHours(dayStartTime).toLocaleTimeString() }}
        </div>
      </spaced-row>
      <spaced-row>
        <w-flex align-center>
          <w-button
              @click="dayStartTime > 0 ? dayStartTime -= 1 : undefined"
              icon="wi-minus"
              bg-color="secondary"
              sm>
          </w-button>
          <w-slider
              v-model="dayStartTime"
              class="mx3 grow"
              label="Day Boundary"
              min="0"
              max="24"
              step="1"
              label-position="left"
              color="green"
              outline>
          </w-slider>

          <w-button
              @click="dayStartTime < 24 ? dayStartTime += 1 : undefined"
              icon="wi-plus"
              bg-color="secondary"
              sm>
          </w-button>
        </w-flex>
      </spaced-row>
      <spaced-row>
        <div class="mb4 mt4">
          The Day Start Time is when a new day begins.
          For example, if the Day Start Time is set to 6 AM,
          everything like food, diaper changes, and medications will be counted from 6 AM today until 6 AM tomorrow.
        </div>
      </spaced-row>
      <spaced-row>
        <div class="text-right">
          <w-button type="submit" class="mt-5 pa3 shadow round">Save</w-button>
        </div>
      </spaced-row>
    </w-flex>
  </w-form>
</template>

<script setup>
import SpacedRow from "@/components/SpacedRow.vue";
import {useSettingsStore} from "@/stores/settings.store";
import {storeToRefs} from "pinia";
import {ref} from "vue";

const settingsStore = useSettingsStore()
const { data } = storeToRefs(settingsStore)
const dayStartTime = ref(data.value.dayStartTime)


function submitForm(event) {
  event.preventDefault();
  settingsStore.update({
    dayStartTime: dayStartTime.value
  })
}

</script>
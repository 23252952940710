import {defineStore} from "pinia";
import {computed, ref} from "vue";
import {authenticationService} from "@/services/authentication.service";
import {userService} from "@/services/user.service";

const authService = authenticationService()
const usersService = userService()

export const useUserStore = defineStore('user', ()=> {
    const loading = ref(false)
    const user = ref(null)

    async function load() {
        loading.value = true;
        try {
            // Call your refresh endpoint to see if the refresh token (from cookie) is valid
            const { access_token, refresh_token } = await authService.refresh();
            if (access_token && refresh_token) {
                const userResponse = await usersService.get()
                console.log(userResponse)
                user.value = {
                    id: userResponse.id,
                    username: userResponse.username,
                    firstName: userResponse.firstName,
                    lastName: userResponse.lastName,
                    roles: userResponse.roles,
                    accountId: userResponse.accountId,
                };
            }
        } catch (err) {
            user.value = null;
        }
        loading.value = false;
    }

    async function login(username,password) {
        loading.value = true
        const response = await authService.login(username, password)
        if(response?.user?.id && response?.access_token && response?.refresh_token) {
            user.value = {
                id: response.user?.id,
                username: response.user?.username,
                firstName: response.user?.firstName,
                lastName: response.user?.lastName,
                roles: response.user?.roles,
                accountId: response.user?.account?.id
            }
            loading.value = false
            return true
        }
        loading.value = false
        return false
    }

    async function logout() {
        loading.value = true
        await authService.logout(user.value.id)
        user.value = null
        loading.value = false
    }

    const signup = async (username, password) => {
        loading.value = true
        const response = await authService.signup(username, password)
        if(response.user) {
            user.value = {
                id: response.user.id,
                username: response.user.username,
                firstName: response.user.firstName,
                lastName: response.user.lastName,
                roles: response.user.roles,
                accountId: response.user?.account?.id
            }
            loading.value = false
            return {
                created: true,
                message: 'User created successfully'
            }
        }
        loading.value = false
        return {
            created: false,
            message: response.message
        }
    }

    const isLoggedIn = computed(()=> {
        return !!user.value;
    })

    return {
        user,
        load,
        login,
        signup,
        logout,
        isLoggedIn,
        loading
    }
})

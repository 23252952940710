<template>
  <div class="ma2">
    <div class="xs6 pa1">
      <w-input type="date" label-position="left" label="Date" outline v-model="date"/>
    </div>
    <div class="xs6 pa1">
      <w-input type="time" label-position="left" label="Time" outline v-model="time"/>
    </div>
    <custom-radio
        :items="[
          {
            label: 'Dry Diaper',
            icon: 'mdi mdi-alert-circle-outline',
            value: DiaperContentEnum.DRY
          },
          {
            label: 'Wet',
            icon: 'mdi mdi-water-outline',
            value: DiaperContentEnum.PEE
          },
          {
            label: 'Poop',
            icon: 'mdi mdi-emoticon-poop-outline',
            value: DiaperContentEnum.POO
          }]"
        v-model="content">
    </custom-radio>
  </div>
  <w-divider></w-divider>
  <div class="text-right">
      <w-button class="ma1" bg-color="secondary" icon="wi-cross" @click="onCancel"></w-button>
      <w-button class="ma1" bg-color="primary" icon="wi-check" :disabled="!valid" @click="onSubmit"></w-button>
  </div>
</template>
<script setup>
import CustomRadio from "@/components/CustomRadio.vue";
import {ref, defineEmits, computed, onMounted} from "vue";
import {DiaperContentEnum} from "@/shared/diaper-content.enum";
import {extractHoursAndMinutesFromDate} from "@/shared/extract-hours-and-minutes-from-date.function";
import {toISOStringWithLocalTimezone} from "@/shared/to-iso-string-with-local-timezone.function";

const $emit = defineEmits(['cancel', 'submit'])

const date = ref(new Date().toISOString().split('T')[0])
const time = ref(toISOStringWithLocalTimezone(new Date()).split('T')[1])
const content = ref('')

const valid = computed(() => content.value !== null)

function onCancel() {
  $emit('cancel')
}
function onSubmit() {
  $emit('submit', {
    date: date.value,
    time: extractHoursAndMinutesFromDate(time.value),
    content: content.value
  })
}
onMounted(()=>{
  date.value = new Date().toISOString().split('T')[0]
  time.value = toISOStringWithLocalTimezone(new Date()).split('T')[1]
  content.value = ''
})





</script>


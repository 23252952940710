<template>
  <div class="baby-details-page">
    <h1>Baby Details</h1>
    <p class="size--sm">
      Review your baby’s details below. Click <strong>Edit</strong> to update any values.
    </p>

    <w-form v-model="valid" @before-validate="validate" @submit="submitForm">
      <!-- Baby Name -->
      <div class="field mt3 mb1">
        <label for="babyName" class="size--sm">What Is The Baby's Name?</label>
        <div v-if="editing">
          <w-input
              id="babyName"
              color="black"
              type="text"
              v-model="babyName"
              :validators="validators"
          ></w-input>
        </div>
        <div v-else>
          <p class="display-text">{{ babyName || 'N/A' }}</p>
        </div>
      </div>

      <!-- Baby Gender -->
      <div class="field mt3 mb1">
        <label class="size--sm">What Is The Baby's Gender?</label>
        <div v-if="editing">
          <w-radios
              v-model="babyGender"
              label-color="black"
              :items="genderItems"
              inline
              :validators="validators"
          ></w-radios>
        </div>
        <div v-else>
          <p class="display-text">{{ genderText }}</p>
        </div>
      </div>

      <!-- Birth Date -->
      <div class="field mt3 mb1">
        <label for="babyBirthDate" class="size--sm">When was The Baby Born?</label>
        <div v-if="editing">
          <w-input
              id="babyBirthDate"
              color="black"
              type="date"
              v-model="babyBirthDate"
              :validators="validators"
          ></w-input>
        </div>
        <div v-else>
          <p class="display-text">{{ babyBirthDate || 'N/A' }}</p>
        </div>
      </div>

      <!-- Birth Weight -->
      <div class="field mt3 mb1">
        <label for="babyWeight" class="size--sm">What Was The Baby's Weight At Birth?</label>
        <div v-if="editing">
          <w-input
              id="babyWeight"
              color="black"
              type="number"
              v-model="babyWeight"
              :validators="validators"
          ></w-input>
        </div>
        <div v-else>
          <p class="display-text">{{ babyWeight || 'N/A' }}</p>
        </div>
      </div>

      <!-- Premature Birth -->
      <div class="field mt3 mb1">
        <label class="size--sm">Was The Baby Born Prematurely?</label>
        <div v-if="editing">
          <w-radios
              v-model="babyPremature"
              label-color="black"
              :items="prematureItems"
              inline
              :validators="validators"
          ></w-radios>
        </div>
        <div v-else>
          <p class="display-text">{{ babyPremature ? 'Yes' : 'No' }}</p>
        </div>
      </div>

      <!-- Expected Birth Date (if premature) -->
      <div class="field mt3 mb1" v-if="babyPremature">
        <label for="babyExpectedBirthDate" class="size--sm">What Was The Expected Birth Date?</label>
        <div v-if="editing">
          <w-input
              id="babyExpectedBirthDate"
              color="black"
              type="date"
              v-model="babyExpectedBirthDate"
              :validators="prematureValidators"
          ></w-input>
        </div>
        <div v-else>
          <p class="display-text">{{ babyExpectedBirthDate || 'N/A' }}</p>
        </div>
      </div>

      <w-divider dark class="mt3"></w-divider>

      <!-- Action Buttons -->
      <div class="buttons mt-5">
        <!-- When not editing, show the Edit button -->
        <w-button
            v-if="!editing"
            @click.prevent="enableEditing"
            class="fill-width shadow round"
        >
          Edit
        </w-button>

        <!-- When editing, show Cancel and Save buttons -->
        <div v-else class="editing-buttons">
          <div class="fill-width">
            <w-button
                @click.prevent="cancelEdit"
                class="fill-width shadow round"
                bg-color="secondary"
                >
              Cancel
            </w-button>
          </div>
          <div class="fill-width">
            <w-button type="submit" class="fill-width shadow round" bg-color="primary">
              Save
            </w-button>
          </div>
        </div>
      </div>
    </w-form>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue';
import { useBabyStore } from '@/stores/baby.store';

// Initialize the baby store (assumed to be implemented via Pinia or similar)
const babyStore = useBabyStore();

// Local state for form fields
const babyName = ref('');
const babyGender = ref('');
const babyBirthDate = ref('');
const babyWeight = ref('');
const babyPremature = ref(false);
const babyExpectedBirthDate = ref('');
const valid = ref(false);

// Flag to track whether the form is in edit mode
const editing = ref(false);

// Validators for required fields (applied only when editing)
const validators = [(value) => !!value || 'This field is required'];

// Validators for the expected birth date when baby is premature
const prematureValidators = [
  (value) => !!value || 'This field is required',
  (value) => {
    const premature = calculateWeeksAndDays(new Date(babyBirthDate.value), new Date(value));
    let weeks = premature.days ? 39 - premature.weeks : 40 - premature.weeks;
    if (weeks < 20) return 'Expected birth date should be at most 20 weeks after the birth date';
    if (weeks > 38) return 'Expected birth date should be at least 2 weeks after birth date';
    return true;
  },
];

// Options for gender and premature radios
const genderItems = [
  { label: 'Boy', value: 'M' },
  { label: 'Girl', value: 'F' },
];

const prematureItems = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
];

// Computed properties to display text values in read-only mode
const genderText = computed(() => {
  if (babyGender.value === 'M') return 'Boy';
  if (babyGender.value === 'F') return 'Girl';
  return 'N/A';
});

// When the component is mounted, load the details from the baby store
onMounted(() => {
  loadBabyDetails();
});

// Load baby details from the store into local state
function loadBabyDetails(){
  babyName.value = babyStore.data.name || '';
  babyGender.value = babyStore.data.gender || '';
  babyBirthDate.value = babyStore.data.birthDate || '';
  babyWeight.value = babyStore.data.birthWeight || '';
  babyPremature.value = babyStore.data.preterm ?? false;
  babyExpectedBirthDate.value = babyStore.data.expectedBirthDate || '';
  console.log(babyName.value)
  console.log(babyGender.value)
  console.log(babyBirthDate.value)
  console.log(babyWeight.value)
  console.log(babyPremature.value)
  console.log(babyExpectedBirthDate.value)

}

// Enable editing mode
function enableEditing() {
  editing.value = true;
}

// Cancel editing and revert changes by reloading from the store
function cancelEdit() {
  editing.value = false;
  loadBabyDetails();
}

// Validate the form (only needed when editing)
function validate() {
  if (!editing.value) {
    valid.value = true;
    return true;
  }
  const isValid = !babyPremature.value
      ? !!babyName.value &&
      !!babyBirthDate.value &&
      babyPremature.value !== null &&
      !!babyWeight.value &&
      !!babyGender.value
      : !!babyName.value &&
      !!babyBirthDate.value &&
      babyPremature.value !== null &&
      !!babyWeight.value &&
      !!babyGender.value &&
      !!babyExpectedBirthDate.value;
  valid.value = isValid;
  return isValid;
}

// When the form is submitted, save the updated details to the baby store
function submitForm(event) {
  if (!valid.value) return;
  event.preventDefault();
  babyStore.saveBabyDetails({
    name: babyName.value,
    gender: babyGender.value,
    birthDate: babyBirthDate.value,
    birthWeight: babyWeight.value,
    preterm: babyPremature.value,
    expectedBirthDate: babyPremature.value ? babyExpectedBirthDate.value : null,
  });
  // Exit edit mode after saving
  editing.value = false;
}

// Helper function to calculate the difference in weeks and days between two dates.
function calculateWeeksAndDays(fromDate, toDate) {
  const oneDay = 24 * 60 * 60 * 1000; // milliseconds in one day
  const diffInTime = toDate - fromDate;
  const diffInDays = Math.floor(diffInTime / oneDay);
  const weeks = Math.floor(diffInDays / 7);
  const days = diffInDays % 7;
  return { weeks, days };
}
</script>

<style scoped>
.baby-details-page {
  max-width: 600px;
  margin: auto;
  padding: 1rem;
}

.field {
  margin-bottom: 1rem;
}

.display-text {
  padding: 0.5rem;
  background-color: #f5f5f5;
  border-radius: 4px;
}

.buttons {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.editing-buttons {
  display: flex;
  gap: 1rem;
}
</style>



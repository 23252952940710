<template>
  <div class="user-profile-page">
    <h1>User Profile</h1>
    <p class="size--sm">
      Review and update your profile details below. Click <strong>Edit</strong> to modify your information.
    </p>

    <w-form v-model="formValid" @before-validate="validate" @submit="submitForm">
      <!-- Details Section -->
      <section class="section">
        <h2>Details</h2>

        <!-- Username -->
        <div class="field mt3 mb1">
          <label for="username" class="size--sm">Username</label>
          <div v-if="editing">
            <w-input
                id="username"
                type="text"
                v-model="username"
                :validators="requiredValidator"
            ></w-input>
          </div>
          <div v-else>
            <p class="display-text">{{ username || 'N/A' }}</p>
          </div>
        </div>

        <!-- First Name -->
        <div class="field mt3 mb1">
          <label for="firstName" class="size--sm">First Name</label>
          <div v-if="editing">
            <w-input
                id="firstName"
                type="text"
                v-model="firstName"
                :validators="requiredValidator"
            ></w-input>
          </div>
          <div v-else>
            <p class="display-text">{{ firstName || 'N/A' }}</p>
          </div>
        </div>

        <!-- Last Name -->
        <div class="field mt3 mb1">
          <label for="lastName" class="size--sm">Last Name</label>
          <div v-if="editing">
            <w-input
                id="lastName"
                type="text"
                v-model="lastName"
                :validators="requiredValidator"
            ></w-input>
          </div>
          <div v-else>
            <p class="display-text">{{ lastName || 'N/A' }}</p>
          </div>
        </div>
      </section>

      <w-divider dark class="mt3"></w-divider>

      <!-- Email Section -->
      <section class="section">
        <h2>Emails</h2>
        <ul class="email-list">
          <li
              v-for="(item, index) in emails"
              :key="index"
              class="email-item"
          >
            <div class="email-info">
              <span class="email-address">{{ item.email }}</span>
              <span v-if="item.primary" class="primary-tag">Primary</span>
              <span v-if="!item.verified" class="unverified-tag">Unverified</span>
            </div>
            <!-- Delete button: only shown in edit mode and when there are multiple emails -->
            <w-button
                v-if="editing && emails.length > 1"
                @click.prevent="deleteEmail(index)"
                icon-only="trash"
                class="icon-button"
            ></w-button>
          </li>
        </ul>

        <!-- Add new email input: only when editing -->
        <div v-if="editing" class="add-email">
          <w-input
              placeholder="Add new email"
              v-model="newEmail"
              type="email"
              :validators="emailValidator"
          ></w-input>
          <w-button
              @click.prevent="addEmail"
              icon-only="plus"
              class="icon-button"
          ></w-button>
        </div>
      </section>

      <w-divider dark class="mt3"></w-divider>

      <!-- Password Reset Section -->
      <section class="section">
        <h2>Reset Password</h2>
        <div class="field mt3 mb1">
          <label for="currentPassword" class="size--sm">Current Password</label>
          <w-input
              id="currentPassword"
              type="password"
              v-model="currentPassword"
              :validators="editing ? requiredValidator : []"
          ></w-input>
        </div>
        <div class="field mt3 mb1">
          <label for="newPassword" class="size--sm">New Password</label>
          <w-input
              id="newPassword"
              type="password"
              v-model="newPassword"
              :validators="editing ? requiredValidator : []"
          ></w-input>
        </div>
      </section>

      <!-- Action Buttons -->
      <div class="buttons mt-5">
        <!-- When not editing, show the Edit button -->
        <w-button
            v-if="!editing"
            @click.prevent="enableEditing"
            class="fill-width shadow round"
        >
          Edit
        </w-button>

        <!-- When editing, show Cancel and Save buttons -->
        <div v-else class="editing-buttons">
          <div class="fill-width">
            <w-button
                @click.prevent="cancelEdit"
                class="fill-width shadow round"
                bg-color="secondary"
            >
              Cancel
            </w-button>
          </div>
          <div class="fill-width">
            <w-button
                type="submit"
                class="fill-width shadow round"
                bg-color="primary"
            >
              Save
            </w-button>
          </div>
        </div>
      </div>
    </w-form>
  </div>
</template>

<script setup>
import { ref } from 'vue';

// Flag for edit mode and form validity
const editing = ref(false);
const formValid = ref(false);

// Profile details state
const username = ref('');
const firstName = ref('');
const lastName = ref('');

// Emails: each email object contains email, verified, and primary properties.
const emails = ref([
  // Initial dummy email. In a real app, these values would be loaded from an API or store.
  { email: 'user@example.com', verified: true, primary: true },
]);

// For adding a new email
const newEmail = ref('');

// Password reset fields
const currentPassword = ref('');
const newPassword = ref('');

// Validators
const requiredValidator = [(value) => !!value || 'This field is required'];
const emailValidator = [
  (value) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(value) || 'Invalid email address';
  },
];

// ---------------------------------------------------
// Methods and helpers

// Load profile data (simulate an API or store fetch)
function loadProfile() {
  // Simulated loaded data:
  username.value = 'john_doe';
  firstName.value = 'John';
  lastName.value = 'Doe';
  // Emails would normally be loaded here as well.
  // Reset password fields on load
  currentPassword.value = '';
  newPassword.value = '';
  newEmail.value = '';
}

// Initialize the page
loadProfile();

// Enable editing mode
function enableEditing() {
  editing.value = true;
}

// Cancel editing and reload data
function cancelEdit() {
  editing.value = false;
  loadProfile();
}

// Add a new email to the list
function addEmail() {
  // Validate that the new email is non-empty and matches the email format
  const validatorResult = emailValidator[0](newEmail.value);
  if (validatorResult !== true) return;
  // Add the new email (it is not primary and unverified by default)
  emails.value.push({
    email: newEmail.value,
    verified: false,
    primary: false,
  });
  newEmail.value = '';
}

// Delete an email if more than one exists
function deleteEmail(index) {
  if (emails.value.length <= 1) return; // cannot delete if it is the only email
  // Remove the selected email
  emails.value.splice(index, 1);
  // Ensure that one email remains marked as primary.
  if (!emails.value.some((item) => item.primary)) {
    // If a verified email exists, mark it as primary; otherwise, mark the first email.
    const verifiedEmail = emails.value.find((item) => item.verified);
    if (verifiedEmail) {
      verifiedEmail.primary = true;
    } else if (emails.value.length) {
      emails.value[0].primary = true;
    }
  }
}

// Form validation: check that required fields are present
function validate() {
  // Validate details section: username, firstName, lastName must be set.
  const detailsValid =
      !!username.value && !!firstName.value && !!lastName.value;
  // Validate password reset: either both fields are empty (no password change) or both are filled.
  const passwordValid =
      (!currentPassword.value && !newPassword.value) ||
      (currentPassword.value && newPassword.value);
  formValid.value = detailsValid && passwordValid;
  return formValid.value;
}

// Submit the form (save changes)
function submitForm(event) {
  if (!validate()) return;
  event.preventDefault();
  // In a real app, you would send the updated data to an API or update your store.
  console.log('Profile saved:', {
    username: username.value,
    firstName: firstName.value,
    lastName: lastName.value,
    emails: emails.value,
    currentPassword: currentPassword.value,
    newPassword: newPassword.value,
  });
  // Exit editing mode after saving.
  editing.value = false;
}
</script>

<style scoped>
.user-profile-page {
  max-width: 600px;
  margin: auto;
  padding: 1rem;
}

.section {
  margin-bottom: 1.5rem;
}

.field {
  margin-bottom: 1rem;
}

.display-text {
  padding: 0.5rem;
  background-color: #f5f5f5;
  border-radius: 4px;
}

/* Email list styling */
.email-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.email-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 0;
  border-bottom: 1px solid #e0e0e0;
}

.email-info {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.primary-tag {
  background-color: #007bff;
  color: #fff;
  padding: 0.2rem 0.5rem;
  border-radius: 3px;
  font-size: 0.8rem;
}

.unverified-tag {
  background-color: #ffc107;
  color: #000;
  padding: 0.2rem 0.5rem;
  border-radius: 3px;
  font-size: 0.8rem;
}

/* New email input area */
.add-email {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-top: 1rem;
}

.icon-button {
  /* Adjust these styles to fit your icon button component */
  min-width: 2rem;
  padding: 0.2rem;
}

/* Buttons layout */
.buttons {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.editing-buttons {
  display: flex;
  gap: 1rem;
}

/* Mobile friendly styles */
@media (max-width: 600px) {
  .user-profile-page {
    padding: 0.5rem;
  }
}
</style>
